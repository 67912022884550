export const FREE_TIME_ACTIVITIES = [
    {
      id: 1,
      text: "Играть в компьютерные игры",
    },
    {
      id: 2,
      text: "Читать книги",
    },
    {
      id: 3,
      text: "Заниматься спортом",
    },
    {
      id: 4,
      text: "Смотреть фильмы",
    },
    {
      id: 5,
      text: "Играть с друзьями",
    },
    {
      id: 6,
      text: "Рисовать",
    },
  ];