export const SCHOOL_SUBJECTS = [
    {
      value: "Math",
      label: "Математика",
    },
    {
      value: "Russian",
      label: "Русский язык",
    },
    {
      value: "Biology",
      label: "Биология",
    },
    {
      value: "Art",
      label: "Искусство (ИЗО)",
    },
    {
      value: "English",
      label: "Английский язык",
    },
  ];