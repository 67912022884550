export const TYPES_OF_SPORT = [
    {
      value: "Football",
      label: "Футбол",
    },
    {
      value: "Basketball",
      label: "Баскетбол",
    },
    {
      value: "Swimming",
      label: "Плавание",
    },
    {
      value: "Golf",
      label: "Гольф",
    },
    {
      value: "Volleyball",
      label: "Волейбол",
    },
  ];