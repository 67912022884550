export const ACTORS = [
  {
    value: "Jackie Chan",
    label: "Джеки Чан",
  },
  {
    value: "Dwayne Johnson",
    label: "Дуэйн Джонсон",
  },
  {
    value: "Bruce Lee",
    label: "Брюс Ли",
  },
  {
    value: "Arnold Schwarzenegger",
    label: "Арнольд Шварценеггер",
  },
];
