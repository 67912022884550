export const LEARNING_OPTIONS = [
    {
      id: 1,
      text: "Космос и звезды",
    },
    {
      id: 2,
      text: "Животные и природа",
    },
    {
      id: 3,
      text: "Спорт и физическая активность",
    },
    {
      id: 4,
      text: "Технологии и изобретения",
    },
    {
      id: 5,
      text: "Искусство и культура",
    },
    {
      id: 6,
      text: "История и древние цивилизации",
    },
  ];