export const COLORS = [
  {
    value: "red",
    label: "Красный",
  },
  {
    value: "orange",
    label: "Оранжевый",
  },
  {
    value: "yellow",
    label: "Желтый",
  },
  {
    value: "green",
    label: "Зеленый",
  },
  {
    value: "blue",
    label: "Голубой",
  },
  {
    value: "darkBlue",
    label: "Синий",
  },
  {
    value: "purple",
    label: "Фиолетовый",
  },
];
