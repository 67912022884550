export const ACTRESSES = [
    {
      value: "Margot Robbie",
      label: "Марго Робби",
    },
    {
      value: "Emma Watson",
      label: "Эмма Уотсон",
    },
    {
      value: "Anne Hathaway",
      label: "Энн Хэтэуэй",
    },
    {
      value: "Jennifer Lawrence",
      label: "Дженнифер Лоуренс",
    },
  ];